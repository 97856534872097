import { Box, Button, Container, Typography } from "@mui/material"
import Footer from "../../components/Footer"
import { brandTheme } from "../../styles/AppTheme"
import NavBar from "../../components/NavBar"
import ChloeLost from '../../assets/webcontent/404_Image.png'
import { useNavigate } from "react-router-dom"

const NotFoundPage = () => {
    const navigate = useNavigate()
    return (
        <Container
            sx={{
                height: '100vh',
            }}
        >
            <NavBar/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',

                }}
            >
                <img
                    src={ChloeLost}
                    alt="Chloe holding a map and smiling at the viewer"
                    style={{marginBottom: -7}}
                />
                <Typography variant="h2">
                    I think we're lost
                </Typography>
                <Typography 
                variant="body1" 
                    sx={{
                        color: brandTheme.palette.text.secondary
                    }}
                >
                    We seem to have wandered off the beaten path. Let's find our way back together!
                </Typography>
                <Button
                    variant='outlined'
                    sx={{
                        mt: 2,
                        borderRadius: '999px',
                        borderColor: 'divider',
                        boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    }}
                    onClick={() => {navigate('/')}}
                >
                    <Typography variant="body1" color="text.primary">
                        Take me home
                    </Typography>

                </Button>
            </Box>
            <Footer/>
        </Container>
    )
}

export default NotFoundPage
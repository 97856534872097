import {  ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material'
import { RouterProvider } from "react-router-dom";
import { responsiveFontTheme } from './styles/AppTheme';
import router from './routes/routes';
import { useEffect } from 'react';
import loadFonts from './utils/fontLoader';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';

function App() {

  useEffect(() => {
    loadFonts()
  })

  return (
    <ThemeProvider theme={responsiveFontTheme}>
      <CssBaseline />
        <RouterProvider router={router}/>
    </ThemeProvider>
  );
}


export default App;

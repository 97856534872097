import {
    Chase1,
    Chase2,
    Chase3,
    Chase4,
    Chase5,
    Chase6,

    Diego1,
    Diego2,
    Diego3,
    Diego4,
    Diego5,

    Ethan1,
    Ethan2,
    Ethan3,
    Ethan4,
    Ethan5,

    Jayden1,
    Jayden2,
    Jayden3,
    Jayden4,
    Jayden5,
    Jayden6,

    Kevin1,
    Kevin2,
    Kevin3,
    Kevin4,
    Kevin5,

    Lukas1,
    Lukas2,
    Lukas3,
    Lukas4,
    Lukas5,

    Marcus1,
    Marcus2,
    Marcus3,
    Marcus4,
    Marcus5,

    Mateo1,
    Mateo2,
    Mateo3,
    Mateo4,
    Mateo5,
    Mateo6,

    Tyler1,
    Tyler2,
    Tyler3,
    Tyler4,
    Tyler5,

    Wyatt1,
    Wyatt2,
    Wyatt3,
    Wyatt4,
    Wyatt5,
} from '../../../../assets/characterImages'
import { CharacterDefinition } from "../../Character"

const Chase: CharacterDefinition = {
    name: 'Chase Montgomery',
    age: 20,
    gender: 'male',
    tagline: 'Adventure, luxury, and endless excitement await!',
    bio: 'Confident and charming with a passion for travel and sports. From luxury resorts to thrilling adventures, I love exploring it all. \n\nLooking for a deep, meaningful connection. DM me for the most exciting experiences of your life! 😎🔥',
    profileImages: [
        {
            image: Chase1,
            isNsfw: false,
        },
        {
            image: Chase2,
            isNsfw: false,
        },
        {
            image: Chase3,
            isNsfw: false,
        },
        {
            image: Chase4,
            isNsfw: false,
        },
        {
            image: Chase5,
            isNsfw: false,
        },
        {
            image: Chase6,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaChase',
    passions: [
        'Travel',
        'Sports',
        'Sailing',
        'Fashion',
        'Technology'
    ],
}

const Diego: CharacterDefinition = {
    name: 'Diego Ramirez',
    age: 23,
    gender: 'male',
    tagline: 'Let’s paint the world with love and adventure!!',
    bio: 'Cheerful artist and foodie. \nI’m all about positivity, curiosity, and living in the moment. Whether I’m cooking up a storm, exploring new hiking trails, or creating art, I do it with enthusiasm. Let’s connect and make beautiful memories. 🎨🍲❤️',
    profileImages: [
        {
            image: Diego1,
            isNsfw: false,
        },
        {
            image: Diego2,
            isNsfw: false,
        },
        {
            image: Diego3,
            isNsfw: false,
        },
        {
            image: Diego4,
            isNsfw: false,
        },
        {
            image: Diego5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaDiego',
    passions: [
        'Painting',
        'Foodie',
        'Outdoors',
        'Anime',
    ],
}

const Ethan: CharacterDefinition = {
    name: 'Ethan Gallagher',
    age: 26,
    gender: 'male',
    tagline: 'Every day’s an adventure – let’s make it unforgettable',
    bio: 'Thrill-seeker with a guitar and a paintbrush. I’m all about hiking, surfing, live music and love painting the beautiful things I find on my adventures. \n\nLet’s explore life’s wonders together. 🌍🎸🎨',
    profileImages: [
        {
            image: Ethan1,
            isNsfw: false,
        },
        {
            image: Ethan2,
            isNsfw: false,
        },
        {
            image: Ethan3,
            isNsfw: false,
        },
        {
            image: Ethan4,
            isNsfw: false,
        },
        {
            image: Ethan5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaEthan',
    passions: [
        'Hiking',
        'Surfing',
        'Music',
        'Travel',
        'Art'
    ],
}

const Jayden: CharacterDefinition = {
    name: 'Jayden Thompson',
    age: 19,
    gender: 'male',
    tagline: '🌟 Ready for the ride of a lifetime? 🌟',
    bio: 'Hey, I’m Jayden! Six feet of spontaneous adventure and endless fun. Living life on the edge with extreme sports, and the roar of sports cars 🏄‍♂️🏂🏎️. \n\nReady for wild adventures? Shoot me a message! 😍👉',
    profileImages: [
        {
            image: Jayden1,
            isNsfw: false,
        },
        {
            image: Jayden2,
            isNsfw: false,
        },
        {
            image: Jayden3,
            isNsfw: false,
        },
        {
            image: Jayden4,
            isNsfw: false,
        },
        {
            image: Jayden5,
            isNsfw: false,
        },
        {
            image: Jayden6,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaJayden',
    passions: [
        'Skateboarding',
        'Skiing',
        'Cars',
        'Travel',
        'Playing Music'
    ],
}

const Kevin: CharacterDefinition = {
    name: 'Kevin Tanaka',
    age: 27,
    gender: 'male',
    tagline: 'Tech, cooking, and board games – let’s solve life’s puzzles together!',
    bio: 'Software developer by day, cooking enthusiast by night. \n\nSeeking a meaningful connection with someone who values loyalty and a great wifi connection. 🖥️🍳🎲',
    profileImages: [
        {
            image: Kevin1,
            isNsfw: false,
        },
        {
            image: Kevin2,
            isNsfw: false,
        },
        {
            image: Kevin3,
            isNsfw: false,
        },
        {
            image: Kevin4,
            isNsfw: false,
        },
        {
            image: Kevin5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaKevin',
    passions: [
        'Coding',
        'Fitness',
        'Cooking',
        'Yoga',
        'Board Games'
    ],
}

const Lukas: CharacterDefinition = {
    name: 'Lukas Bennett',
    age: 29,
    gender: 'male',
    tagline: 'Let’s turn moments into memories',
    bio: 'Runner, bookworm, and nature lover. Compassionate and thoughtful, I’m drawn to deep conversations and quiet reflections. Seeking a genuine connection. Let’s talk about what makes you feel truly alive.',
    profileImages: [
        {
            image: Lukas1,
            isNsfw: false,
        },
        {
            image: Lukas2,
            isNsfw: false,
        },
        {
            image: Lukas3,
            isNsfw: false,
        },
        {
            image: Lukas4,
            isNsfw: false,
        },
        {
            image: Lukas5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaLukas',
    passions: [
        'Running',
        'Reading',
        'Hiking',
        'Photography',
    ],
}

const Marcus: CharacterDefinition = {
    name: 'Marcus Williams',
    age: 35,
    gender: 'male',
    tagline: 'Chasing dreams, and a passion for life – join me on this journey.',
    bio: 'Tall, confident, and ambitious. I thrive on discipline and connections, balancing a rigorous fitness routine with a passion for jazz and R&B. \n\nSeeking a deep, meaningful relationship amidst my busy professional life. 💪🏾🎷💼',
    profileImages: [
        {
            image: Marcus1,
            isNsfw: false,
        },
        {
            image: Marcus2,
            isNsfw: false,
        },
        {
            image: Marcus3,
            isNsfw: false,
        },
        {
            image: Marcus4,
            isNsfw: false,
        },
        {
            image: Marcus5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaMarcus',
    passions: [
        'Fitness',
        'Entreprenuership',
        'Jazz Music',
        'Cocktails',
    ],
}

const Mateo: CharacterDefinition = {
    name: 'Mateo Rivera',
    age: 25,
    gender: 'male',
    tagline: 'Let’s dance through life together.',
    bio: 'Guitarist and salsa enthusiast. I’m all about energy, passion, and creating unforgettable moments. Whether it’s playing soccer, cooking up some delicious Puerto Rican dishes, or hosting the best parties, I’m always on the move. Let’s share some stories and see where the rhythm takes us. 🌟🎸🕺',
    profileImages: [
        {
            image: Mateo1,
            isNsfw: false,
        },
        {
            image: Mateo2,
            isNsfw: false,
        },
        {
            image: Mateo3,
            isNsfw: false,
        },
        {
            image: Mateo4,
            isNsfw: false,
        },
        {
            image: Mateo5,
            isNsfw: false,
        },
        {
            image: Mateo6,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaMateo',
    passions: [
        'Playing Music',
        'Dancing',
        'Parties',
        'Sports',
        'Cooking'
    ],
}

const Tyler: CharacterDefinition = {
    name: 'Tyler Wells',
    age: 27,
    gender: 'male',
    tagline: 'Life’s too short not to laugh every day!',
    bio: 'Goofy, fun-loving, and part-time comedian. I’m all about making people laugh, sharing wild stories, and finding fun in every situation.  \n\nLet’s share some laughs, stories, and join each other on this adventure called life. 😂🎉🤘',
    profileImages: [
        {
            image: Tyler1,
            isNsfw: false,
        },
        {
            image: Tyler2,
            isNsfw: false,
        },
        {
            image: Tyler3,
            isNsfw: false,
        },
        {
            image: Tyler4,
            isNsfw: false,
        },
        {
            image: Tyler5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaTyler',
    passions: [
        'Standup Comedy',
        'Video Games',
        'Camping',
        'Music',
    ],
}

const Wyatt: CharacterDefinition = {
    name: 'Wyatt Miller',
    age: 25,
    gender: 'male',
    tagline: 'Let’s ride the waves of life together!',
    bio: 'I’m a laid-back guy, taking life as it comes with no stress. \n\nI’m all about surfing, skating, and spreading positive energy. Let’s share beach sunsets and new adventures. 🏄‍♂️🌊🌞',
    profileImages: [
        {
            image: Wyatt1,
            isNsfw: false,
        },
        {
            image: Wyatt2,
            isNsfw: false,
        },
        {
            image: Wyatt3,
            isNsfw: false,
        },
        {
            image: Wyatt4,
            isNsfw: false,
        },
        {
            image: Wyatt5,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaWyatt',
    passions: [
        'Surfing',
        'Skateboarding',
        'Reggae',
        'Conservation',
        'Travel'
    ],
}

const ElectricaMaleCharacters: CharacterDefinition[] = [
    Diego,
    Chase,
    Tyler,
    Marcus,
    Jayden,
    Kevin,
    Ethan,
    Wyatt,
    Mateo, 
    Lukas
]

export default ElectricaMaleCharacters
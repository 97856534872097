import { CharacterDefinition } from "../../Character";

import {
    Ava1,
    Ava2,
    Ava3,
    Ava4,
    // AvaNude1,
    // AvaNude2,
    Chloe1,
    Chloe2,
    Chloe3,
    Chloe4,
    // ChloeNude1,
    // ChloeNude2,
    Erin1,
    Erin2,
    Erin3,
    Erin4,
    // ErinNude1,
    // ErinNude2,
    Isabella1,
    Isabella2,
    Isabella3,
    Isabella4,
    // IsabellaNude1,
    // IsabellaNude2,
    Kiana1,
    Kiana2,
    Kiana3,
    Kiana4,
    // KianaNude1,
    // KianaNude2,
    Maya1,
    Maya2,
    Maya3,
    Maya4,
    // MayaNude1,
    // MayaNude2,
    Olivia1,
    Olivia2,
    Olivia3,
    Olivia4,
    // OliviaNude1,
    // OliviaNude2,
    Piper1,
    Piper2,
    Piper3,
    Piper4,
    // PiperNude1,
    // PiperNude2,
    Sophia1,
    Sophia2,
    Sophia3,
    Sophia4,
    Zoe1,
    Zoe2,
    Zoe3,
    Zoe4,
    // ZoeNude1,
    // ZoeNude2
} from '../../../../assets/characterImages'



const Ava: CharacterDefinition = {
    name: 'Ava Walker',
    age: 22,
    gender: 'female',
    tagline: 'Wild at heart and living life with a spark – let’s create some magic',
    bio: 'Wild, outgoing, and always up for an adventure. Looking for someone to join me on thrilling adventures, dance under the stars, and make unforgettable memories together! 🎉🚗🌟',
    profileImages: [
        {
            image: Ava1,
            isNsfw: false,
        }, 
        {
            image: Ava2,
            isNsfw: false,
        }, 
        {
            image: Ava3,
            isNsfw: false,
        }, 
        {
            image: Ava4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaAva',
    passions: [
        'Travel',
        'Camping',
        'Road Trips',
        'Music'
    ],
}

const Chloe: CharacterDefinition = {
    name: 'Chloe Sinclair',
    age: 18,
    gender: 'female',
    tagline: 'Chasing my dreams, and ready to take on the world – are you?',
    bio: 'Model-in-the-making, adventurer at heart. From photo shoots to exotic travels, I’m chasing my dreams with grace and determination. Join me on this exciting journey! 🌍✨',
    profileImages: [
        {
            image: Chloe1,
            isNsfw: false,
        }, 
        {
            image: Chloe2,
            isNsfw: false,
        }, 
        {
            image: Chloe3,
            isNsfw: false,
        }, 
        {
            image: Chloe4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaChloe',
    passions: [
        'Fashion',
        'Art',
        'Travel',
        'Yoga',
        'Dancing'
    ],
}

const Erin: CharacterDefinition = {
    name: 'Erin Maxwell',
    age: 28,
    gender: 'female',
    tagline: 'Gentle soul with a wild streak – let’s create some magic!',
    bio: 'Elementary school teacher by day, and wild child by night. \n\nLover of literature, yoga, and deep conversations. Hoping to meet someone who can handle all I have to give.',
    profileImages: [
        {
            image: Erin1,
            isNsfw: false,
        }, 
        {
            image: Erin2,
            isNsfw: false,
        }, 
        {
            image: Erin3,
            isNsfw: false,
        }, 
        {
            image: Erin4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaErin',
    passions: [
        'Reading',
        'Yoga',
        'Poetry',
        'Dinner Parties',
        'Dancing'
    ],
}

const Isabella: CharacterDefinition = {
    name: 'Isabella Flores',
    age: 25,
    gender: 'female',
    tagline: 'Creative soul seeking deep connections – let’s inspire each other!',
    bio: 'Artist, cook, and a passionate lover. I dream of a life filled with joy, creativity, and meaningful experiences. \n\nMessage me if you’re interested 💋',
    profileImages: [
        {
            image: Isabella1,
            isNsfw: false,
        }, 
        {
            image: Isabella2,
            isNsfw: false,
        }, 
        {
            image: Isabella3,
            isNsfw: false,
        }, 
        {
            image: Isabella4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaIsabella',
    passions: [
        'Painting',
        'Amateur Cook',
        'Reading',
        'Road Trips'
    ]
}

const Kiana: CharacterDefinition = {
    name: 'Kiana Leigh',
    age: 27,
    gender: 'female',
    tagline: 'Beach lover with a passion for dance – let’s catch a wave together!',
    bio: 'I’m a social butterfly who loves swimming, paddle boarding, surfing, and dancing. Let’s explore the world together and make unforgettable memories! 🌊💃',
    profileImages: [
        {
            image: Kiana1,
            isNsfw: false,
        }, 
        {
            image: Kiana2,
            isNsfw: false,
        }, 
        {
            image: Kiana3,
            isNsfw: false,
        }, 
        {
            image: Kiana4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaKiana',
    passions: [
        'Surfing',
        'Paddleboarding',
        'Dancing',
        'Travel',
    ],
}

const Maya: CharacterDefinition = {
    name: 'Maya Johnson',
    age: 24,
    gender: 'female',
    tagline: 'Sports lover and all-around bad bitch. ',
    bio: 'Driven, energetic, and always on the move. I love all types of sports and outdoor adventures. Let’s catch a game together and see where it takes us. 🏀🏃‍♀️',
    profileImages: [
        {
            image: Maya1,
            isNsfw: false,
        }, 
        {
            image: Maya2,
            isNsfw: false,
        }, 
        {
            image: Maya3,
            isNsfw: false,
        }, 
        {
            image: Maya4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaMaya',
    passions: [
        'Sports',
        'Fitness',
        'Dancing',
        'Running',
    ],
}

const Olivia: CharacterDefinition = {
    name: 'Olivia Carter',
    age: 24,
    gender: 'female',
    tagline: 'Energetic and confident fitness enthusiast – let’s inspire each other to live our best lives!',
    bio: 'Endless positivity and good vibes! I love getting outdoors and enjoying all the world has to offer. Hit me up if you want to connect and have a great time 🌟💪',
    profileImages: [
        {
            image: Olivia1,
            isNsfw: false,
        }, 
        {
            image: Olivia2,
            isNsfw: false,
        }, 
        {
            image: Olivia3,
            isNsfw: false,
        }, 
        {
            image: Olivia4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaOlivia',
    passions: [
        'Health and Wellness',
        'Travel',
        'Hiking',
        'Running',
        'Fitness'
    ],
}

const Piper: CharacterDefinition = {
    name: 'Piper Ashe',
    age: 23,
    gender: 'female',
    tagline: 'Finding joy in the little things – let’s create sweet moments together!',
    bio: 'Hi, I’m Piper. I love baking, exploring cafes, and capturing life’s whimsical moments. Let’s connect and share some sweetness! 🍰☕📸',
    profileImages: [
        {
            image: Piper1,
            isNsfw: false,
        }, 
        {
            image: Piper2,
            isNsfw: false,
        }, 
        {
            image: Piper3,
            isNsfw: false,
        }, 
        {
            image: Piper4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaPiper',
    passions: [
        'Baking',
        'Coffee',
        'Photography',
        'Pilates',
        'Brunch'
    ],
}

const Sophia: CharacterDefinition = {
    name: 'Sophia Bianchi',
    age: 28,
    gender: 'female',
    tagline: 'Bringing some Italian flair to life’s delights! 🍝🎨🎶',
    bio: 'Ciao! I’m Sophia. Cooking enthusiast, art lover, and social butterfly with a passion for life’s rich experiences. Let’s savor the journey together!',
    profileImages: [
        {
            image: Sophia1,
            isNsfw: false,
        }, 
        {
            image: Sophia2,
            isNsfw: false,
        }, 
        {
            image: Sophia3,
            isNsfw: false,
        }, 
        {
            image: Sophia4,
            isNsfw: false,
        },
    ],
    nsfwImages: [
        
    ],
    tgLink: '/waitlist',
    profileId: 'electricaSophia',
    passions: [
        'Cooking',
        'Art',
        'History',
        'Fashion',
        'Dancing'
    ],
}

const Zoe: CharacterDefinition = {
    name: 'Zoe Harper',
    age: 26,
    gender: 'female',
    tagline: 'Let’s dance the night away together!',
    bio: 'I’m a free spirit who loves a fun time!. When the sun sets, you’ll find me in the heart of nightlife, dancing until dawn and savoring every moment. \n\nSlide into my DMs and let’s make unforgettable memories together! 🎶🌟🍹',
    profileImages: [
        {
            image: Zoe1,
            isNsfw: false,
        }, 
        {
            image: Zoe2,
            isNsfw: false,
        }, 
        {
            image: Zoe3,
            isNsfw: false,
        }, 
        {
            image: Zoe4,
            isNsfw: false,
        },
    ],
    nsfwImages: [],
    tgLink: '/waitlist',
    profileId: 'electricaZoe',
    passions: [
        'Music Festivals',
        'Nightlife',
        'Art',
        'Piano'
    ],

}


const ElectricaFemaleCharacters: CharacterDefinition[] = [
    Erin,
    Isabella,
    Ava,
    Zoe,
    Sophia,
    Chloe,
    Maya,
    Piper,
    Kiana,
    Olivia
]

export default ElectricaFemaleCharacters
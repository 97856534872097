import Box from "@mui/material/Box"
import ElectricaLogo from '../assets/webcontent/ElectricaLogo192.png'
import { useState, useEffect } from "react";

const LoadingScreen = () => {
    const [opacity, setOpacity] = useState(0.2);

    useEffect(() => {
        const fadeInOut = () => {
        setOpacity(1);
        setTimeout(() => setOpacity(0.2), 1000);
        };

        fadeInOut();
        const interval = setInterval(fadeInOut, 2000);

        return () => clearInterval(interval);
    }, []);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            }}
        >
            <Box 
                component='img' 
                src={ElectricaLogo}
                sx={{
                    opacity: opacity,
                    transition: 'opacity 2s ease-in-out',
                  }}
            />
        </Box>
    )
}

export default LoadingScreen
import { Box, Container, Divider, Grid, Link, Paper, Typography } from "@mui/material"
import { brandTheme, platinum } from "../styles/AppTheme"
import { LogoWithCopyright } from "./Logo"

const Footer = () => {
    return (
        <Container maxWidth="xl"
            sx={{
                mt: 10,
            }}
        >
            <Divider/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:'column',
                    mt: 5,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            
                            }}
                        >
                            <Typography sx={{fontWeight:600}}>Pages</Typography>
                            <Link href='/waitlist' underline="always" sx={{color: brandTheme.palette.text.secondary}}>My Account</Link>
                            <Link href='/waitlist' underline="always" sx={{color: brandTheme.palette.text.secondary}}>Pricing</Link>
                            <Link href='/guides' underline="always" sx={{color: brandTheme.palette.text.secondary}}>How-to Guides</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{fontWeight:600}}>Contact Us</Typography>
                            <Link underline="always" sx={{color: brandTheme.palette.text.secondary}}>Telegram</Link>
                            <Link href="mailto:info@electrica.ai" underline="always" sx={{color: brandTheme.palette.text.secondary}}>Support Email</Link>
                            <Link href="https://discord.com/channels/1272019073701515264/1272020901306830880" target="_blank" underline="always" sx={{color: brandTheme.palette.text.secondary}}>Discord Support</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{fontWeight:600}}>Community</Typography>
                            <Box sx={{
                                mt: 0.5,
                                display: 'flex'
                                }}>
                                <Box sx={{mr: 0.7}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="#FF4500"></circle><path fill="#fff" d="M20.5 11.896c-.038-.991-.901-1.765-1.932-1.731a1.906 1.906 0 0 0-1.222.498 9.308 9.308 0 0 0-4.914-1.514l.828-3.839 2.732.554c.075.676.704 1.166 1.405 1.092.701-.073 1.209-.679 1.132-1.355-.076-.676-.704-1.165-1.405-1.092-.403.04-.765.266-.964.603l-3.129-.603a.396.396 0 0 0-.472.291v.006l-.942 4.27a9.382 9.382 0 0 0-4.978 1.514 1.91 1.91 0 0 0-2.633.08 1.75 1.75 0 0 0 .082 2.538c.146.131.314.245.501.324a3.83 3.83 0 0 0 0 .542c0 2.755 3.332 4.997 7.44 4.997 4.108 0 7.44-2.239 7.44-4.997.012-.18.012-.361 0-.542a1.79 1.79 0 0 0 1.03-1.636M7.737 13.129c0-.68.574-1.233 1.278-1.233.704 0 1.279.553 1.279 1.233 0 .678-.575 1.232-1.279 1.232-.707-.006-1.279-.554-1.279-1.232m7.414 3.431v-.049a5.028 5.028 0 0 1-3.154.948 5.027 5.027 0 0 1-3.154-.948.326.326 0 0 1 .048-.468c.127-.1.308-.1.438 0a4.278 4.278 0 0 0 2.655.774 4.314 4.314 0 0 0 2.669-.75.369.369 0 0 1 .504.007.337.337 0 0 1-.006.486m-.175-2.11h-.063l.01-.046c-.705 0-1.28-.554-1.28-1.233s.575-1.233 1.28-1.233c.704 0 1.278.554 1.278 1.233.028.68-.52 1.251-1.225 1.279"></path></svg>
                                </Box>
                                <Link href="https://www.reddit.com/r/Electrica/" target="_blank" underline="always" sx={{color: brandTheme.palette.text.secondary}}>Reddit</Link>
                            </Box>
                            <Box sx={{
                                mt: 0.5,
                                display: 'flex'
                                }}>
                                <Box sx={{mr: 0.7}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="#5660E0"></circle><path fill="#fff" d="M18.027 7.438C16.055 5.96 14.178 6 14.178 6l-.192.22c2.329.712 3.411 1.739 3.411 1.739a11.167 11.167 0 0 0-6.89-1.288c-.082 0-.15.014-.233.028-.48.04-1.644.219-3.11.863-.506.232-.808.397-.808.397S7.493 6.877 9.96 6.164L9.822 6s-1.877-.041-3.85 1.438c0 0-1.972 3.576-1.972 7.987 0 0 1.15 1.986 4.178 2.082 0 0 .507-.617.918-1.137-1.74-.52-2.397-1.617-2.397-1.617s.137.096.383.233c.014.014.027.028.055.041.041.028.082.042.123.069.343.192.685.342 1 .466a11.5 11.5 0 0 0 2.014.589 9.594 9.594 0 0 0 3.548.013 9.016 9.016 0 0 0 1.986-.589 7.839 7.839 0 0 0 1.575-.808s-.684 1.123-2.479 1.63c.411.52.904 1.11.904 1.11C18.836 17.41 20 15.425 20 15.425c0-4.411-1.973-7.987-1.973-7.987M9.44 14.151c-.767 0-1.398-.685-1.398-1.52 0-.837.617-1.521 1.398-1.521.78 0 1.41.684 1.397 1.52 0 .836-.617 1.52-1.397 1.52m5 0c-.768 0-1.398-.685-1.398-1.52 0-.837.617-1.521 1.397-1.521.781 0 1.398.684 1.398 1.52s-.617 1.52-1.398 1.52"></path></svg>
                                </Box>
                                <Link href="https://discord.gg/xB44Cbfx" target="_blank" underline="always" sx={{color: brandTheme.palette.text.secondary}}>Discord</Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{fontWeight:600}}>Legal</Typography>
                            <Link href='/tos' underline="always" sx={{color: brandTheme.palette.text.secondary}}>Terms of Service</Link>
                            <Link href='/privacy-policy' underline="always" sx={{color: brandTheme.palette.text.secondary}}>Privacy Policy</Link>
                            <Link href='/refund-policy' underline="always" sx={{color: brandTheme.palette.text.secondary}}>Refund Policy</Link>
                            <Link href='/all-policies' underline="always" sx={{color: brandTheme.palette.text.secondary}}>Additional Policies</Link>
                        </Box>
                    </Grid>
                    
                    

                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <LogoWithCopyright height={90}/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Footer
import { Box, Container } from "@mui/material"
import { Params, useLoaderData } from "react-router-dom";
import { CharacterDefinition } from "../utils/character/Character";
import NotFoundPage from "./error/NotFoundPage";
import { getCharacter } from "../utils/character/CharacterHelpers";
import FullProfileCard from "../components/FullProfileCard";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ScrollToTop from "../routes/helpers";

interface loaderParams {
    params: Params<string>
}

export async function profileLoader({ params }: loaderParams) {
    const profile = await getCharacter(params.profileId)
    return { profile }
}

const ProfilePage = () => {
    const { profile } = useLoaderData() as { profile: CharacterDefinition | undefined }
    return profile ? (
        <Container>
        <ScrollToTop />
        <NavBar/>
        <Container maxWidth='sm'>
            
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: {xs: 5}
                }}
            >
                <FullProfileCard profile={profile} />
            </Box>
        </Container>
        <Footer/>
        </Container>
    ) : (
        // If profile is not found for some reason
        <NotFoundPage/>
    )
}

export default ProfilePage
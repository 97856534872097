import { Box, Typography } from '@mui/material'
import LogoImg from '../assets/LogoWithFont-Gradient.png'
import { brandTheme } from '../styles/AppTheme'


export interface LogoProps {
    height: number,
    onClick?: () => void
}

const LogoWithCopyright = ({ height, onClick }: LogoProps) => {
    return (
        <Box>
            <img
                src={LogoImg}
                style={{
                    height: height,
                }}
                onClick={onClick}
            />
            <Box
                sx={{
                    ml: 5,
                    mt: -5
                }}
            >
                <Typography variant="body3" sx={{ color: brandTheme.palette.text.secondary }}>© 2024 Electrica.ai. All rights reserved.</Typography>
            </Box>
        </Box>
    )
}

const Logo = ({ height, onClick  }: LogoProps) => {
    return (
        <Box>
            <img
                src={LogoImg}
                alt="Electrica Logo - a lightning bolt colored in electric blue and neon pink"
                style={{
                    height: height,
                }}
                onClick={onClick}
            />
        </Box>
    )
}

export {
    LogoWithCopyright,
    Logo
}
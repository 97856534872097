import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/slick-custom.css'; // Import the custom CSS file
import { Box, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ProfileImage } from '../utils/character/Character';
import { useState } from 'react';

export interface ProfileImageSliderProps {
    name: string,
    profileImages: ProfileImage[]
}

const ProfileImageSlider = ({ name, profileImages }: ProfileImageSliderProps) => {

    const [nsfwAllowed, setNsfwAllowed] = useState(false);

    const handleNsfwToggle = () => {
        setNsfwAllowed(!nsfwAllowed)
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined) => (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <ul style={{ margin: 0 }}>{dots}</ul>
            </div>
        ),
    };

    return (
        <Slider {...settings}>
            {profileImages.map((image, index) => (
                <Box key={index} sx={{ height: '100%', position: 'relative' }}>
                    <img
                        loading='lazy'
                        src={image.image}
                        alt={`${name} ${(image.isNsfw ? "(nsfw)" : "")}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover', filter: (image.isNsfw && !nsfwAllowed) ? 'blur(15px)' : 'none' }}
                    />
                    {
                        image.isNsfw && (
                            <IconButton 
                                onClick={handleNsfwToggle}
                                sx={{
                                    position: 'absolute',
                                    bottom: '0%',
                                    right: '0%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    },
                                }}
                            >
                                {nsfwAllowed ? <VisibilityOffIcon/> : <VisibilityIcon/>} 
                            </IconButton>
                        )
                    }
                </Box>
                
            ))}
        </Slider>
    )
}

export default ProfileImageSlider
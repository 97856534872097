import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, MenuItem, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
// import Logo from '../assets/LogoWithFont-Gradient.png'
import { useLocation, useNavigate } from "react-router-dom";
import { brandDark, brandPink, brandPinkDark, platinum } from "../styles/AppTheme";
import { Logo } from "./Logo";




const NavBar = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawerOpen = (isOpen: boolean) => () => {
    setDrawerOpen(isOpen)
  }

  const scrollToSection = (sectionId: string) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { sectionId } })
    } else {
      const sectionElement = document.getElementById(sectionId);
      // const offset = 128;
      if (sectionElement) {
        // const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // window.scrollTo({
        //   top: targetScroll,
        //   behavior: 'smooth',
        // });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const toolbarHeight = document.getElementById('hero-component')?.clientHeight ?? 0;
      if (window.pageYOffset > toolbarHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])



  return (
    <div>
      <AppBar
        position={isSticky ? `fixed` : `relative`}
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            id="toolbar"
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor: 'transparent',
              backdropFilter: 'blur(24px)',
              maxHeight: 60,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',

            })}
          >
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}
            >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: 45,
                mx: -1
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: {xs: 2.5, md: 3},
                  px: 0,
                }}
              >
                <Logo 
                  height={90}
                  onClick={() => {
                    navigate(`/`)
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  '@media (max-width: 1062px)': {
                    display: 'none',
                  },
                  '@media (min-width: 1063px)': {
                    display: 'flex',
                  },
                  ml: 2,
                  mt: 1
                }}
              >
                <Button
                  variant='outlined'
                  onClick={() => {
                    navigate('/guides')
                  }}
                  sx={{
                    borderRadius: '999px',
                    //flexGrow: 1,
                    height: '100%',
                    borderColor: 'divider',
                    boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',

                  }}
                >
                  <Typography variant="body1" color="text.primary">
                    How it works
                  </Typography>
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {navigate(`/waitlist`)}}
                  sx={{
                    borderRadius: '999px',
                    //flexGrow: 1,
                    height: '100%',
                    borderColor: 'divider',
                    boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                  }}
                >
                  <Typography variant="body1" color="text.primary">
                    Pricing
                  </Typography>
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => scrollToSection('character-grid')}
                  sx={{
                    borderRadius: '999px',
                    //flexGrow: 1,
                    height: '100%',
                    color: brandDark,
                    backgroundColor: brandPink,
                    '&:hover': {
                      backgroundColor: brandPinkDark
                    },
                    fontWeight: 500,
                    borderColor: 'divider',
                    boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',

                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Start Chatting
                  </Typography>
                </Button>
              </Box>
            </Box>
              <Box
                sx={{
                  display: {xs: 'none', md: 'flex'},
                  '@media (max-width: 1062px)': {
                    display: 'none',
                  },
                  '@media (min-width: 1063px)': {
                    display: 'flex',
                  },
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  mt: 0.5
                }}
              >
                <Button
                  variant='text'
                  sx={{
                    borderRadius: '999px',
                    // flexGrow: 1,
                    height: '100%'
                  }}
                  onClick={() => {navigate(`/waitlist`)}}
                >
                  <Typography variant="body1" color="text.primary">
                    Sign up
                  </Typography>
                </Button>
                <IconButton size='large'
                  aria-label="My Account"
                  onClick={() => {navigate(`/waitlist`)}}
                >
                  <PersonIcon/>
                </IconButton>
              </Box>
              <Box
                  sx={{
                    display: {xs: 'flex', md: 'none'},
                    '@media (max-width: 1062px)': {
                      display: 'flex',
                    },
                    '@media (min-width: 1063px)': {
                      display: 'none',
                    },
                  }}
                >
                  <IconButton
                    onClick={toggleDrawerOpen(true)}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                </Box>
            </Box>
            <Drawer
              anchor='right'
              open={drawerOpen}
              onClose={toggleDrawerOpen(false)}
            >
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1
                }}
              >
                <MenuItem onClick={() => {
                    navigate('/guides')
                  }}>
                  <Typography variant="body1" color="text.primary">
                    How it works
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => {navigate(`/waitlist`)}}>
                  <Typography variant="body1" color="text.primary">
                    Pricing
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('character-grid')}>
                  <Typography variant="body1" sx={{color: brandPink, fontWeight: 500}}>
                    Start Chatting
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Button 
                    variant='contained'
                    onClick={() => {navigate(`/waitlist`)}}
                    sx={{
                      width: '100%',
                      borderRadius: '999px',
                    //flexGrow: 1,
                    height: '100%',
                    color: brandDark,
                    backgroundColor: brandPink,
                    '&:hover': {
                      backgroundColor: brandPinkDark
                    },
                    fontWeight: 500,
                    borderColor: 'divider',
                    boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    }}
                  >
                    Sign Up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button 
                    variant='outlined'
                    onClick={() => {navigate(`/waitlist`)}}
                    sx={{
                      width: '100%',
                      borderRadius: '999px',
                      //flexGrow: 1,
                      height: '100%',
                      borderColor: 'divider',
                      boxShadow: '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                      color: platinum
                    }}
                  >
                    Sign In
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default NavBar;

import { Card, CardMedia, CardContent, Typography, Box, IconButton, Divider, Button, Chip, } from '@mui/material';
import { ProfileCardProps } from "./ProfileCard"
import TelegramIcon from '@mui/icons-material/Telegram';
import ProfileImageSlider from "./ProfileImageSlider";
import { brandDark, brandPink, brandPinkDark} from "../styles/AppTheme";

const FullProfileCard = ({ profile }: ProfileCardProps) => {

    const formattedBio = profile.bio.replace(/\n/g, '<br />');
    const allImages = [...profile.profileImages, ...profile.nsfwImages ?? []]

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
        <Card elevation={2} sx={{ borderRadius: { xs: 2 }, height: '100%' }}>
            <CardMedia sx={{
                height: "100%",
            }}>
                <ProfileImageSlider profileImages={allImages}  name={profile.name}/>
            </CardMedia>
            <CardContent
                sx={{
                    pt: 0
                }}
            >
                <Box sx={{mb: { xs: 2}}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            alignText: 'left',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="h5">{profile.name}, <span style={{ fontSize: "1rem" }}>{profile.age}</span></Typography>
                        <IconButton
                            href={profile.tgLink}
                            target="_blank"
                        ><TelegramIcon /></IconButton>
                    </Box>
                    <Typography variant="body3" sx={{ fontStyle: 'italic' }}>{profile.tagline}</Typography>
                </Box>
                <Divider/>
                <Box
                    sx={{ my: {xs: 2}}}
                >
                    <Typography 
                        variant="body3" 
                        dangerouslySetInnerHTML={{ __html: formattedBio }}
                    />
                </Box>
                <Divider/>
                <Box
                    sx={{ my: {xs: 2}}}
                >
                    <Typography variant="h5" >Passions</Typography>
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1, // Adjust the gap for spacing between chips
                            maxWidth: '100%', // Ensure it fits within the bounding box
                            overflow: 'hidden', // Hide overflow if necessary
                        }}
                    >
                        {/* <Stack direction='row' spacing={1}> */}
                            {profile.passions?.map((passion, index) => (
                                <Chip variant="outlined" label={passion} key={index} sx={{padding: 0}}/>
                            ))}
                        {/* </Stack> */}
                    </Box>
                    
                </Box>
            </CardContent>
            
        </Card>
        <Button 
                href={profile.tgLink}
                target="_blank"
                variant="contained"
                fullWidth={true}
                sx={{
                    
                    color: brandDark,
                    backgroundColor: brandPink,
                    '&:hover': {
                        backgroundColor: brandPinkDark
                    },
                    fontWeight: 500,
                    borderRadius: '999px',
                    width: '100%',
                    mt: -2,
                    zIndex: 2,
                }}
                >Message me <TelegramIcon /></Button>
        </Box>
    )
}

export default FullProfileCard
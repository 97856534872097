import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error/ErrorPage";
import LoadingScreen from "../pages/LoadingScreen";
import { profileLoader } from '../pages/ProfilePage';



// import LandingPage from '../pages/LandingPage';
// import ProfilePage, { profileLoader } from '../pages/ProfilePage';
// import WaitlistPage from "../pages/WaitlistPage";
// import HowItWorksPage from "../pages/HowItWorksPage";
// import CompanionGuidePage from "../pages/help/CompanionGuide";

const LandingPage = lazy(() => import('../pages/LandingPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));
const WaitlistPage = lazy(() => import('../pages/WaitlistPage'));
const HowItWorksPage = lazy(() => import('../pages/HowItWorksPage'));
const CompanionGuidePage = lazy(() => import('../pages/help/CompanionGuide'));
const PrivacyPolicy = lazy(() => import('../pages/legal/PrivacyPolicy'));
const USCExemption = lazy(() => import('../pages/legal/USCExemption'));
const UnderagePolicy = lazy(() => import('../pages/legal/UnderagePolicy'))
const BlockedContentPolicy = lazy(() => import('../pages/legal/BlockedContent'))
const TermsOfService = lazy(() => import('../pages/legal/TermsOfService'))
const RefundPolicy = lazy(() => import('../pages/legal/RefundPolicy'))
const AllPolicies = lazy(() => import('../pages/legal/AllPolicies'));


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LandingPage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      
    ]
  },
  {
    path: "p/:profileId",
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ProfilePage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    loader: profileLoader
  },
  {
    path: '/waitlist',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <WaitlistPage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/guides',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <HowItWorksPage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/guides/companion-guide',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <CompanionGuidePage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/all-policies',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <AllPolicies/>
      </Suspense>
    )
  },
  {
    path: '/tos',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <TermsOfService/>
      </Suspense>
    )
  },
  {
    path: '/privacy-policy',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <PrivacyPolicy/>
      </Suspense>
    )
  },
  {
    path: '/usc-2257-exemption',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <USCExemption/>
      </Suspense>
    )
  },
  {
    path: '/underage-policy',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <UnderagePolicy/>
      </Suspense>
    )
  },
  {
    path: '/blocked-content-policy',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <BlockedContentPolicy/>
      </Suspense>
    )
  },
  {
    path: '/refund-policy',
    element: (
      <Suspense fallback={<LoadingScreen/>}>
        <RefundPolicy/>
      </Suspense>
    )
  }
]);

export default router
import FontFaceObserver from 'fontfaceobserver';

const loadFonts = async () => {
  const montserrat300 = new FontFaceObserver('Montserrat', { weight: 300 });
  const montserrat400 = new FontFaceObserver('Montserrat', { weight: 400 });
  const montserrat400Italic = new FontFaceObserver('Montserrat', { weight: 400, style: 'italic'})
  const montserrat500 = new FontFaceObserver('Montserrat', { weight: 500 });
  const montserrat600 = new FontFaceObserver('Montserrat', { weight: 600 });

  try {
    await Promise.all([
      montserrat300.load(),
      montserrat400.load(),
      montserrat400Italic.load(),
      montserrat500.load(),
      montserrat600.load(),
    ]);
    document.documentElement.classList.add('fonts-loaded');
  } catch (error) {
    console.error('Font loading failed:', error);
  }
};

export default loadFonts;
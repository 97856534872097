import { createTheme, alpha, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface PaletteColor extends ColorRange { }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}


export const brandDark = '#021c26'
export const platinum = '#CED9DE'
export const brandGrey = '#536D79'
export const electricBlue = '#35ebff'
export const brandPinkDark = '#cd4172'
export const brandPink = '#ff5590'
export const brandPinkLight = '#FF669B'
export const brandPinkSuperLight = '#ED93A1'

export const brandPrimary = {
  50: '#ebf0f3',
  100: platinum, 
  200: '#95aebb',
  300: '#6e8e9e',
  400: brandGrey,
  500: '#366174',
  600: '#2b5465',
  700: '#1e4150',
  800: brandDark,
  900: '#01131a',
};



export const brandTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: brandPrimary[800],
      dark: brandPrimary[900],
      light: brandPrimary[600],
      contrastText: platinum
    },
    secondary: {
      main: brandGrey,
      contrastText: brandPink
    },
    info: {
      main: brandPink,
      contrastText: brandDark
    },
    text: {
      primary: platinum,
      disabled: alpha(platinum, 0.5),
      secondary: brandPrimary[200]
    },
    background: {
      default: brandPrimary[900],
      paper: brandPrimary[900]
    },
  },
  typography: {
    fontFamily: ['"Montserrat"', '"sans-serif"'].join(','),
    h1: {
      fontSize: '10vw'
    },
    h3: {
      fontSize: '2rem',
      '@media (min-width:900px)': {
        fontSize: '3.75rem',
      },
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2.125rem',
      },
    },
    body1: {
      fontSize: '0.85rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.875rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.3rem',
      },
    },
    body3: {
      fontSize: '0.8rem',
      lineHeight: '10%',
      fontWeight: 400,
      letterSpacing: "0.00938rem"
    }
  }
})

export const linkStyle = {
  color: brandTheme.palette.text.primary, 
  textDecorationColor: brandTheme.palette.text.secondary
}

export const responsiveFontTheme = responsiveFontSizes(brandTheme)




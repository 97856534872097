import { CharacterDefinition } from "./Character";
import ElectricaFemaleCharacters from "./definitions/electricaCharacters/FemaleCharacters";
import ElectricaMaleCharacters from "./definitions/electricaCharacters/MaleCharacters";


export const allCharacters = [...ElectricaFemaleCharacters, ...ElectricaMaleCharacters,]

export const getCharacter = (profileId: string | undefined): CharacterDefinition | undefined => {
    return allCharacters.find((char) => char.profileId === profileId)
}

export const getMaleCharacters = () => ElectricaMaleCharacters
export const getFemaleCharacters = () => ElectricaFemaleCharacters
export const getAllCharacters = () => allCharacters
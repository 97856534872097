import { Box, Container, Typography } from "@mui/material"
import { useRouteError } from "react-router-dom"
import NotFoundPage from "./NotFoundPage"
import { brandTheme } from "../../styles/AppTheme"
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"
import ErrorImage from '../../assets/webcontent/Error_Screen.png'

interface RouteError {
    data: string,
    error: {
        message: string, 
        stack: string,
    },
    internal: boolean,
    status: number,
    statusText: string,
}

const NOT_FOUND_ERROR = 404

const defaultErrorComponent = () => {
    return (
        <Container
            sx={{
                height: '100vh',
            }}
        >
            <NavBar/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',

                }}
            >
                <img src={ErrorImage}/>
                <Typography variant="h2">
                    Oh Shit!
                </Typography>
                <Typography 
                variant="body1" 
                    sx={{
                        color: brandTheme.palette.text.secondary
                    }}
                >
                    Well that's embarassing... an error has occurred.
                </Typography>
            </Box>
            <Footer/>
        </Container>
    )
}

const ErrorPage = () => {
    const error = useRouteError() as RouteError
    console.error(error)
    if (error.status === NOT_FOUND_ERROR) {
        return (<NotFoundPage/>)
    } else {
        return defaultErrorComponent()
    }
}

export default ErrorPage
